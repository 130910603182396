import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import KeyboardControls from 'app/utils/SliderKeyboardControls'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef } from 'react'

import { Props as ReviewProps, Review } from './Review'

const AUTOPLAY_TIMER = 5000

export interface Props {
  languageCode: string
  reviews: ReviewProps[]
}

export const CustomerReviews = memo(function CustomerReviews({
  languageCode,
  reviews,
}: Props) {
  if (!reviews) {
    return null
  }

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      animationEnded(s) {
        setCurrentSlide(s.track.details.rel)
      },
    },
    [KeyboardControls],
  )

  useEffect(() => {
    timer.current = setInterval(() => {
      if (instanceRef && reviews.length > 1) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <Wrapper>
        <Title>{useVocabularyData('about-us', languageCode)}</Title>
        <Slider ref={sliderRef}>
          {uniq(reviews).map((item, index) => (
            <Review className="keen-slider__slide" key={index} {...item} />
          ))}
        </Slider>
        {reviews.length > 1 ? (
          <Dots dial={2} row>
            {reviews.map((item, index) => (
              <Dot
                key={index}
                className={currentSlide === index ? 'active' : undefined}
                onClick={() => {
                  instanceRef.current?.moveToIdx(index)
                }}
              />
            ))}
          </Dots>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem 0 10.625rem;
  padding: 0 3.333vw;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 7.5rem 0 5.625rem;
    padding: 0 1.875rem;
  }
`

const Wrapper = styled.div``

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;
`

const Slider = styled.div`
  display: flex;
  max-width: 46.875rem;
  outline: none;
  overflow: hidden;
  margin: auto;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 100%;
    max-width: 38.4375rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
`

const Dots = styled(FlexBox)`
  margin: 2.625rem auto 0;
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight3};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  margin: 0 0.625rem;
  &:first-of-type {
    margin-top: 0;
  }
  &.active {
    opacity: 1;
    pointer-events: none;
  }
`
